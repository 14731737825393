import * as React from 'react';
import { graphql } from 'gatsby';
import makeStyles from '@material-ui/styles/makeStyles';
import type { Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TopContainer from '../components/TopContainer/TopContainer';
import BottomSection from '../components/BottomSection/BottomSection';
import AppBar from '../components/AppBar/AppBar';
import SEOHelmet from '../helmet/SEOHelmet';
import LegalFooter from '../components/Footer/LegalFooter';
import { useTranslation, useI18next, Link } from 'gatsby-plugin-react-i18next';
import LanguageSwitcher, { languageMap } from '../components/LanguageSwitcher/LanguageSwitcher';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      width: '100%',
    },
    heroImage: {
      display: 'block',
      width: '100%',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up(600)]: {
        height: 'fit-content',
        width: '40%',
        flexShrink: 0,
      },
      [theme.breakpoints.up(682)]: {
        width: '50%',
        marginBottom: 0,
      },
    },
    sectionContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '0 25px',
      maxWidth: 1006,
      margin: '0 auto',
      '& h2': {
        color: theme.palette.primary.main,
        fontSize: 22,
        lineHeight: '26.07px',
        fontWeight: 700,
        letterSpacing: -0.5,
        textAlign: 'flex-start',
        marginBottom: 20,
      },
      [theme.breakpoints.up(600)]: {
        padding: '0 50px',
        '& h2': {
          fontSize: 36,
          lineHeight: '42.66px',
        },
      },
      [theme.breakpoints.up(1200)]: {
        padding: `20px 0 0`,
        '& h2': {
          fontSize: 44,
          lineHeight: '50px',
          marginBottom: theme.spacing(6),
        },
      },
    },
    description: {
      fontSize: '16px',
      lineHeight: '1.5',
      '$title + &': {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
      },
      '& h5': {
        fontSize: '24px',
      },
      '& h6': {
        fontSize: '20px',
      },
      '& h5, & h6': {
        marginBottom: theme.spacing(2),
      },
      '& p': {
        marginBottom: theme.spacing(3),
      },
      '& b, & strong': {
        fontWight: 'bold',
      },
      '& a': {
        color: theme.palette.primary.main,
      },
      '& li': {
        ...theme.typography.body1,
      },
    },
  };
});

const SEO_IMAGE = {
  url: 'https://images.prismic.io/uptime-website/5ca7ccc3-2514-4ce0-8e8a-593edac0e2a8_UptimeApp.png?auto=compress,format',
  alt: '',
  dimensions: {
    width: '800px',
    height: '420px',
  }
}

const PrivacyPolicyPage = ({ data, location, pageContext }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { language, languages, originalPath, i18n } = useI18next();
  return (
    <>
      <SEOHelmet
        title={t('privacyPolicyPage.seo.title')}
        description={t('privacyPolicyPage.seo.description')}
        location={location}
        image={SEO_IMAGE}
        locale={pageContext?.language}
        htmlAttributes={{
          dir: i18n.dir(),
        }}
      />
      <AppBar overrideButtons={<LanguageSwitcher hideDivider />} removeLogoLink disableBurgerMenu />
      <main className={classes.root}>
        <TopContainer isReverse>
          <img className={classes.heroImage} src="/img/privacy-policy/hero.png" alt={t('privacyPolicyPage.heroImage.alt')} />
          <Typography variant="h1">{t('privacyPolicyPage.title')}</Typography>
        </TopContainer>
        <div className={classes.sectionContent}>
          <div className={classes.description} dangerouslySetInnerHTML={{ __html: t(`privacyPolicyPage.description1`) }} />
          <div className={classes.description} dangerouslySetInnerHTML={{ __html: t(`privacyPolicyPage.description2`) }} />
          <div className={classes.description} dangerouslySetInnerHTML={{ __html: t(`privacyPolicyPage.description3`) }} />
          <div className={classes.description} dangerouslySetInnerHTML={{ __html: t(`privacyPolicyPage.description4`) }} />
          <div className={classes.description} dangerouslySetInnerHTML={{ __html: t(`privacyPolicyPage.description5`) }} />
          <div className={classes.description} dangerouslySetInnerHTML={{ __html: t(`privacyPolicyPage.description6`) }} />
          <div className={classes.description} dangerouslySetInnerHTML={{ __html: t(`privacyPolicyPage.description7`) }} />
          <div className={classes.description} dangerouslySetInnerHTML={{ __html: t(`privacyPolicyPage.description8`) }} />
          <div className={classes.description} dangerouslySetInnerHTML={{ __html: t(`privacyPolicyPage.description9`) }} />
          <div className={classes.description} dangerouslySetInnerHTML={{ __html: t(`privacyPolicyPage.description10`) }} />
          <div className={classes.description} dangerouslySetInnerHTML={{ __html: t(`privacyPolicyPage.description11`) }} />
          <div className={classes.description} dangerouslySetInnerHTML={{ __html: t(`privacyPolicyPage.description12`) }} />
          <div className={classes.description} dangerouslySetInnerHTML={{ __html: t(`privacyPolicyPage.description13`) }} />
          <div className={classes.description} dangerouslySetInnerHTML={{ __html: t(`privacyPolicyPage.description14`) }} />
          <div className={classes.description} dangerouslySetInnerHTML={{ __html: t(`privacyPolicyPage.description15`) }} />
          <div className={classes.description} style={{ width: '100%' }}>
            <ul>
              {languages.map((lang) => {
                if (language === lang) {
                  return null;
                }
                return <li><Link to={originalPath} language={lang}>{languageMap[lang] || t(`languageSwitcher.options.${lang}`)}</Link></li>
              })}
            </ul>
          </div>
        </div>
        <BottomSection bgColor="#03091D" />
      </main>
      <LegalFooter />
    </>
  );
};

export default PrivacyPolicyPage;

export const query = graphql`
  query PrivacyPolicyPageQuery($language: String) {
    locales: allLocale(filter: {language: {in: ["en-US", $language]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
